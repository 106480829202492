/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ReactComponent as AgdIcon } from 'assets/usage/agd.svg';
import { ReactComponent as AkcesoriaDlaZwierzatIcon } from 'assets/usage/akcesoria-dla-zwierzat.svg';
import { ReactComponent as AkcesoriaDomoweIcon } from 'assets/usage/akcesoria-domowe.svg';
import { ReactComponent as AkcesoriaSportoweIcon } from 'assets/usage/akcesoria-sportowe.svg';
import { ReactComponent as AlkoholIcon } from 'assets/usage/alkohol.svg';
import { ReactComponent as ArtykulySpozywczeIcon } from 'assets/usage/artykuly-spozywcze.svg';
import { ReactComponent as CiezkiePrzedmiotyIcon } from 'assets/usage/ciezkie-przedmioty.svg';
import { ReactComponent as DokumentyIcon } from 'assets/usage/dokumenty.svg';
import { ReactComponent as DrobnaElektronikaIcon } from 'assets/usage/drobna-elektronika.svg';
import { ReactComponent as ElektronikaIcon } from 'assets/usage/elektronika.svg';
import { ReactComponent as GryPlanszoweIcon } from 'assets/usage/gry-planszowe.svg';
// import { ReactComponent as KarmaDlaZwierzatIcon } from 'assets/usage/karma-dla-zwierzat.svg';
import { ReactComponent as KosmetykiIcon } from 'assets/usage/kosmetyki.svg';
import { ReactComponent as KsiazkiIcon } from 'assets/usage/ksiazki.svg';
import { ReactComponent as LekiIcon } from 'assets/usage/leki.svg';
import { ReactComponent as MagazynowanieIcon } from 'assets/usage/magazynowanie.svg';
import { ReactComponent as ObuwieIcon } from 'assets/usage/obuwie.svg';
import { ReactComponent as OdziezIcon } from 'assets/usage/odziez.svg';
import { ReactComponent as RoweryIcon } from 'assets/usage/rowery.svg';
import { ReactComponent as ZabezbieczanieIcon } from 'assets/usage/zabezpieczanie.svg';

export * from 'SourceRoute/ProductPage/ProductPage.config';

export const usageMap = {
    AGD: <AgdIcon />,
    'akcesoria dla zwierząt': <AkcesoriaDlaZwierzatIcon />,
    'akcesoria domowe': <AkcesoriaDomoweIcon />,
    'akcesoria sportowe': <AkcesoriaSportoweIcon />,
    alkohol: <AlkoholIcon />,
    'artykuły spożywcze': <ArtykulySpozywczeIcon />,
    'ciężkie przedmioty': <CiezkiePrzedmiotyIcon />,
    dokumenty: <DokumentyIcon />,
    'drobna elektronika': <DrobnaElektronikaIcon />,
    elektronika: <ElektronikaIcon />,
    'gry planszowe': <GryPlanszoweIcon />,
    // 'karma dla zwierząt': <KarmaDlaZwierzatIcon />,
    kosmetyki: <KosmetykiIcon />,
    książki: <KsiazkiIcon />,
    leki: <LekiIcon />,
    magazynowanie: <MagazynowanieIcon />,
    obuwie: <ObuwieIcon />,
    odzież: <OdziezIcon />,
    rowery: <RoweryIcon />,
    zabezbieczenia: <ZabezbieczanieIcon />,
};
