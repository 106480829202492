/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    Suspense,
} from 'react';

import { ProductGalleryComponent as SourceProductGalleryComponent } from 'SourceComponent/ProductGallery/ProductGallery.component';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import './ProductGallery.override.style';

export const CarouselScroll = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-overlays" */
    'Component/CarouselScroll'
));

/** @namespace Poprawa/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGalleryComponent {
    state = {
        scrollEnabled: true,
        slidesCount: 5,
        prevZoom: false,
    };

    renderAdditionalPictures() {
        const {
            gallery,
            isImageZoomPopupActive,
            activeImage,
            onActiveImageChange,
            isWithEmptySwitcher,
        } = this.props;

        const { slidesCount } = this.state;

        if (gallery.length === 1) {
            return <div block="ProductGallery" elem="Additional" mods={ { isWithEmptySwitcher } } />;
        }

        return (
            <div block="ProductGallery" elem="Additional" mods={ { isImageZoomPopupActive } }>
                <Suspense fallback={ <div /> }>
                    <CarouselScroll
                      activeItemId={ activeImage }
                      onChange={ onActiveImageChange }
                      showedItemCount={ slidesCount }
                      isImageZoomPopupActive={ isImageZoomPopupActive }
                    >
                        { gallery.map(this.renderAdditionalPicture.bind(this)) }
                    </CarouselScroll>
                </Suspense>
            </div>
        );
    }
}

export default ProductGalleryComponent;
