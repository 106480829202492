/* eslint-disable import/prefer-default-export */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const OG_TYPE_PRODUCT = 'product';
export const OG_TYPE_CATEGORY = 'product.group';
export const OPEN_GRAPH_PATH = 'wysiwyg/';
export const OPEN_GRAPH_IMAGE = 'og_image.png';
