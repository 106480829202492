/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPageContainer as SourceContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { getAttributesWithValues } from 'Util/Product';

/** @namespace Poprawa/Route/ProductPage/Container */
export class ProductPageContainer extends SourceContainer {
    containerProps() {
        const { product } = this.props;

        return {
            ...super.containerProps(),
            attributesWithValues: getAttributesWithValues(product),
        };
    }

    updateMeta() {
        const { updateMetaFromProduct } = this.props;
        const {
            name = '',
            meta_title = '',
            meta_keyword = '',
            canonical_url,
            meta_description = '',
            media_gallery_entries,
            variants,
            url,
        } = this.getDataSource();

        updateMetaFromProduct({
            name,
            meta_title,
            meta_keyword,
            canonical_url,
            meta_description,
            media_gallery_entries,
            variants,
            url,
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
