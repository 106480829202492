/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import './ProductLabels.style';

/** @namespace Poprawa/Component/ProductLabels/Component/ProductLabels */
// eslint-disable-next-line react/prop-types
export const ProductLabels = ({ labels = [], isMobile }) => {
    if (!labels || labels?.length === 0) {
        return null;
    }

    const renderLabels = (labels, mods = false) => {
        if (labels?.length === 0) {
            return null;
        }

        return (
            <div
              block="ProductLabels"
              elem="Labels"
              mods={ { topLabels: mods } }
            >
                { labels.map(({
                    name, position, font_color, background_color, css_class,
                }) => {
                    const positionStyle = position || 'default';
                    const cssClass = css_class ? { block: 'ProductLabels', elem: css_class } : null;

                    return (
                        <div
                          block="ProductLabels"
                          elem="Label"
                          mods={ { positionStyle } }
                          style={ { color: font_color, backgroundColor: background_color } }
                          mix={ cssClass }
                        >
                            { name }
                        </div>
                    );
                }) }
            </div>
        );
    };

    const promotionTopLabel = labels?.filter((label) => label?.css_class === 'top');

    const bottomLabels = labels?.filter((label) => label?.css_class !== 'top');

    return (
        <>
            { !isMobile && renderLabels(promotionTopLabel, true) }
            { renderLabels(bottomLabels, false) }
        </>
    );
};

export default ProductLabels;
