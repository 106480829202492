/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const DEFAULT_MAX_PRODUCTS = 99;
export const ONE_HUNDRED_PERCENT = 100;
export const ATTRIBUTES_GROUP_ONE = ['typ_towaru', 'ilosc_na_palecie', 'przelicznik_m2', 'm2_wg_brutto', 'rozmiar_palety', 'paczkomat', 'wymiar_wewnetrzny', 'wymiar_zewnetrzny', 'zastosowanie'];
export const ATTRIBUTES_GROUP_TWO = 'wymiar_wewnetrzny';
export const ATTRIBUTES_GROUP_THREE = 'wymiar_zewnetrzny';
