/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ImageZoomPopupComponent as SourceImageZoomPopupComponent } from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.override.style';

/** @namespace Poprawa/Component/ImageZoomPopup/Component */
export class ImageZoomPopupComponent extends SourceImageZoomPopupComponent {}

export default ImageZoomPopupComponent;
