/* eslint-disable react/forbid-prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductLabels from './ProductLabels.component';

/** @namespace Poprawa/Component/ProductLabels/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    labels: state.LabelReducer?.labels,
    isMobile: state.ConfigReducer.device.isMobile,
});
/** @namespace Poprawa/Component/ProductLabels/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Poprawa/Component/ProductLabels/Container */
export class ProductLabelsContainer extends PureComponent {
    static propTypes = {
        discount: PropTypes.object.isRequired,
        labels: PropTypes.array.isRequired,
        sf_labels: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
    };

    getLabels() {
        const { discount, labels = [], sf_labels = '' } = this.props;

        const labelIds = sf_labels?.split(',') ?? [];

        const filteredLabels = labels.filter(({ id }) => labelIds.includes(id));

        const labelsWithVerifiedDiscount = filteredLabels
            .filter(({ show_discount }) => !(show_discount && discount?.percentOff === 0));

        return labelsWithVerifiedDiscount.map((label) => {
            if (label?.show_discount) {
                return {
                    ...label,
                    name: `-${Math.ceil(discount?.percentOff)}%`,
                };
            }

            return label;
        });
    }

    render() {
        const { isMobile } = this.props;

        return <ProductLabels labels={ this.getLabels() } isMobile={ isMobile } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductLabelsContainer);
